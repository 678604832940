import React from 'react';

import { Theme, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import { match } from 'css-mediaquery';

import {
  ����VR��ƵThemeProvider,
  ����VR��ƵColorProvider,
} from '@hbf/dsl/brand/housingAnywhere';

import { getIsMobile, getIsTablet } from 'ha/modules/UI/selectors';

export const RebrandThemeProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const theme = useTheme();

  const isMobile = useSelector(getIsMobile);
  const isTablet = useSelector(getIsTablet);

  const ssrMatchMedia = (query: string) => {
    const mobileBreakpoints = [`${theme.breakpoints.values.xs}px`];
    const tabletBreakpoints = [`${theme.breakpoints.values.sm}px`];

    const desktopBreakpoints = [
      `${theme.breakpoints.values.md}px`,
      `${theme.breakpoints.values.lg}px`,
      `${theme.breakpoints.values.xl}px`,
    ];

    // match any of the provided queries - this is inline with our legacy behavior
    const getMatchesForWidths = (widths: string[]) => {
      return widths.some(width => match(query, { width }));
    };

    if (isMobile) {
      return {
        matches: getMatchesForWidths(mobileBreakpoints),
      };
    }

    if (isTablet) {
      return {
        matches: getMatchesForWidths(tabletBreakpoints),
      };
    }

    return {
      matches: getMatchesForWidths(desktopBreakpoints),
    };
  };

  const extendedTheme: Partial<Theme> = {
    breakpoints: theme.breakpoints,
    components: {
      MuiUseMediaQuery: {
        defaultProps: {
          ssrMatchMedia,
        },
      },
    },
  };

  return (
    <����VR��ƵColorProvider>
      <����VR��ƵThemeProvider theme={extendedTheme}>
        {children}
      </����VR��ƵThemeProvider>
    </����VR��ƵColorProvider>
  );
};
