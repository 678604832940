import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { useIntl } from 'ha/i18n';
import { Img } from 'ha/ui/Img';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
  Color as TypographyColor,
  units,
} from '@hbf/dsl/legacy';
import { useSearchParams } from 'react-router-dom';

import StudentenBlueLogo from 'ha/img-src/logos/studenten-blue.svg';
import ZwischenmieteBlackLogo from 'ha/img-src/logos/zwischenmiete-black.svg';
import RentmateLogo from 'ha/img-src/logos/rentmate.png';
import StanzaZooLogo from 'ha/img-src/logos/stanzazoo.png';

import {
  StudentenWG,
  StudentenWGColor,
  Rentmate,
  Zwischenmitte,
  RentmateBgColor,
  RentmateBtnColor,
  StanzaZoo,
} from './constants';
import { useKrugerNotification } from './hooks';

const useStyles = makeStyles()(theme => ({
  studentenLearnMore: {
    color: StudentenWGColor,
    borderColor: StudentenWGColor,
    '&:hover': {
      color: StudentenWGColor,
      borderColor: StudentenWGColor,
    },
  },
  studentenGetStarted: {
    color: theme.palette.common.white,
    backgroundColor: StudentenWGColor,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: StudentenWGColor,
    },
  },
  logo: {
    width: units.rem(11.3125),
  },
  rentMatePaper: {
    backgroundColor: RentmateBgColor,
  },
  rentMateLearnMore: {
    color: 'white',
    backgroundColor: RentmateBtnColor,
    border: RentmateBtnColor,
    '&:hover': {
      color: 'white',
      backgroundColor: RentmateBtnColor,
      border: RentmateBtnColor,
    },
  },
  rentMateGetStarted: {
    color: RentmateBtnColor,
    backgroundColor: 'white',
    '&:hover': {
      color: RentmateBtnColor,
      backgroundColor: 'white',
    },
  },
}));

interface SourceProps {
  logo: string;
  text: string;
  learnMoreBtnProps?: ButtonProps;
  getStartedBtnProps?: ButtonProps;
  url: string;
  textColor?: TypographyColor;
  paperClassName?: string;
}

export const KrugerDialog: React.FC = React.memo(() => {
  const { classes } = useStyles();
  const { T, urlResolver } = useIntl();
  const [searchParams] = useSearchParams();
  const { open, handleClose, utmSource } = useKrugerNotification(searchParams);

  if (!open) {
    return null;
  }

  const krugerSources: { [key: string]: SourceProps } = {
    [StudentenWG]: {
      logo: StudentenBlueLogo,
      text: T('modal_notification_studentenwg'),
      learnMoreBtnProps: {
        className: classes.studentenLearnMore,
      },
      getStartedBtnProps: {
        className: classes.studentenGetStarted,
      },
      url: urlResolver.getKrugerPRUrl(),
    },
    [Zwischenmitte]: {
      logo: ZwischenmieteBlackLogo,
      text: T('modal_notification_zwischenmiete'),
      url: urlResolver.getKrugerPRUrl(),
    },
    [Rentmate]: {
      logo: RentmateLogo,
      text: T('modal_notification_rentmate'),
      textColor: 'white',
      paperClassName: classes.rentMatePaper,
      learnMoreBtnProps: {
        className: classes.rentMateLearnMore,
      },
      getStartedBtnProps: {
        className: classes.rentMateGetStarted,
      },
      url: urlResolver.getRenmateFAQUrl(),
    },
    [StanzaZoo]: {
      logo: StanzaZooLogo,
      text: T('modal_notification_stanzazoo'),
      learnMoreBtnProps: {
        color: 'primary',
      },
      getStartedBtnProps: {
        color: 'primary',
      },
      url: urlResolver.getStanzaZooArticleUrl(),
    },
  };

  const {
    logo,
    text,
    textColor,
    url,
    learnMoreBtnProps,
    getStartedBtnProps,
    paperClassName,
  } = krugerSources[utmSource];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={paperClassName ? { paper: paperClassName } : undefined}
    >
      <DialogTitle>
        <Img
          src={logo}
          alt="����VR��Ƶ"
          className={classes.logo}
          dataTestLocator="Kruger Logo"
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography.Paragraph
            data-test-locator="Kruger Dialog Text"
            component="span"
            color={textColor ? 'white' : undefined}
          >
            {text}
          </Typography.Paragraph>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Link to={url} data-test-locator="Kruger Learn More">
          <Button color="secondary" variant="outlined" {...learnMoreBtnProps}>
            {T('cta_learn_more')}
          </Button>
        </Link>

        <Button
          color="secondary"
          variant="contained"
          onClick={handleClose}
          data-test-locator="Kruger Dialog Close"
          {...getStartedBtnProps}
        >
          {T('cta_get_started')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
