// these imports must be relative, otherwise tokamak is unable to parse them
/* eslint-disable import/no-relative-packages */

import { FontInfo } from 'ha/components/LoadFonts';
import HAGroteskBold from '../../../../../node_modules/@hbf/dsl/assets/fonts/HAGrotesk-Bold.woff2';
import InterRegular from '../../../../../node_modules/@hbf/dsl/assets/fonts/Inter-Regular.woff2';
import InterSemiBold from '../../../../../node_modules/@hbf/dsl/assets/fonts/Inter-SemiBold.woff2';
import InterBold from '../../../../../node_modules/@hbf/dsl/assets/fonts/Inter-Bold.woff2';

export const haGroteskFont: FontInfo = {
  fontName: 'HA Grotesk',
  fontFamily: `'HA Grotesk', 'Source Sans Pro', 'Helvetica', 'Arial', 'sans-serif'`,
  preloadWoff2Files: [HAGroteskBold],
  stage1Files: [
    {
      src: `url('${HAGroteskBold}') format('woff2')`,
      options: {
        weight: '700',
        style: 'normal',
        display: 'swap',
      },
    },
  ],
  stage2Files: [],
};

export const interFont: FontInfo = {
  fontName: 'Inter',
  fontFamily: `'Inter', 'Helvetica', 'Arial', 'sans-serif'`,
  preloadWoff2Files: [InterRegular],
  stage1Files: [
    {
      src: `local('Inter'), url('/dist/${InterRegular}') format('woff2')`,
      options: {
        weight: '400',
        style: 'normal',
        display: 'swap',
      },
    },
  ],
  stage2Files: [
    {
      src: `local('Inter'), url('/dist/${InterSemiBold}') format('woff2')`,
      options: {
        weight: '600',
        style: 'normal',
        display: 'swap',
      },
    },
    {
      src: `local('Inter'), url('/dist/${InterBold}') format('woff2')`,
      options: {
        weight: '700',
        style: 'normal',
        display: 'swap',
      },
    },
  ],
};
