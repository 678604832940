import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { Button, IconButton, List } from '@hbf/dsl/core';
import { Login, Menu, UserPlus } from '@hbf/icons/brand-bold';
import {
  HeadSet,
  Help,
  HomeWork,
  Payments,
  QuestionAnswer,
} from '@hbf/icons/brand-regular';

import { useIntl } from 'ha/i18n';

import { SmartTrack as Track } from 'ha/components/track/SmartTrack';
import { LanguageSwitcher } from 'ha/modules/LanguageSwitcher/components/LanguageSwitcher';
import { AuthButtonLogin } from '../AuthButtonLogin/AuthButtonLogin';
import { AuthButtonRegister } from '../AuthButtonRegister/AuthButtonRegister';
import { NavigationDrawer } from '../NavigationDrawer/NavigationDrawer';
import { usePage } from 'ha/modules/Page/contexts';

import { OnboardingContactUs } from '../OnboardingLinks/OnboardingContactUs';
import { OnboardingCustomerSupportChat } from '../OnboardingLinks/OnboardingCustomerSupportChat';
import { OnboardingHelp } from '../OnboardingLinks/OnboardingHelp';

const useStyles = makeStyles()(theme => ({
  drawerAuthContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(5),
  },
  drawerOnboardingContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const LoggedOutQuickActionsAuthMenu = () => {
  const { category } = usePage();

  return (
    <React.Fragment>
      <Track name="Clicked Register in NavBar" pageCategory={category}>
        <AuthButtonRegister
          component={Button}
          fullWidth
          variant="outlined"
          color="neutral"
          startIcon={<UserPlus />}
        />
      </Track>
      <Track name="Clicked Login in NavBar" pageCategory={category}>
        <AuthButtonLogin
          component={Button}
          fullWidth
          variant="outlined"
          color="neutral"
          startIcon={<Login />}
        />
      </Track>
    </React.Fragment>
  );
};

const LoggedOutQuickActionsOnboardingMenu = () => {
  const { T, urlResolver } = useIntl();

  const { category } = usePage();

  return (
    <List size="lg">
      <Track
        name="Clicked How it works in NavBar"
        type="tenant"
        pageCategory={category}
      >
        <List.Item
          component="a"
          startIcon={<HomeWork />}
          href={urlResolver.getRenting()}
        >
          {T('nav.header.how_it_works')}
        </List.Item>
      </Track>

      <Track
        name="Clicked ����VR��ƵLink in NavBar"
        type="tenant"
        pageCategory={category}
      >
        <List.Item
          component="a"
          startIcon={<Payments />}
          href={urlResolver.get����VR��ƵTenantsUrl()}
        >
          {T('nav.header.pricing')}
        </List.Item>
      </Track>

      <OnboardingHelp.Track>
        <OnboardingHelp.Link component={List.Item} startIcon={<Help />}>
          <OnboardingHelp.Label />
        </OnboardingHelp.Link>
      </OnboardingHelp.Track>

      <OnboardingCustomerSupportChat.Link
        component={List.Item}
        startIcon={<QuestionAnswer />}
      >
        <OnboardingCustomerSupportChat.Label />
      </OnboardingCustomerSupportChat.Link>

      <OnboardingContactUs.Link component={List.Item} startIcon={<HeadSet />}>
        <OnboardingContactUs.Label />
      </OnboardingContactUs.Link>
    </List>
  );
};
export const LoggedOutQuickActionsDrawer = () => {
  const { classes } = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  if (isLargerThanMd && isDrawerOpen) {
    setIsDrawerOpen(false);
  }
  return (
    <React.Fragment>
      <IconButton
        onClick={() => setIsDrawerOpen(true)}
        data-test-locator="Navigation/MoreActionsButton"
        aria-label="Menu button"
      >
        <Menu />
      </IconButton>
      <NavigationDrawer
        open={isDrawerOpen}
        data-test-locator="Navigation/MoreActionsMenu"
        onClose={() => setIsDrawerOpen(false)}
      >
        <NavigationDrawer.Block className={classes.drawerAuthContainer}>
          <LoggedOutQuickActionsAuthMenu />
        </NavigationDrawer.Block>

        <div className={classes.drawerOnboardingContainer}>
          <LoggedOutQuickActionsOnboardingMenu />
        </div>

        <NavigationDrawer.Block>
          <LanguageSwitcher size="full-primary" />
        </NavigationDrawer.Block>
      </NavigationDrawer>
    </React.Fragment>
  );
};
