import React from 'react';

import { TooltipProps } from '@mui/material';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { KycStates } from '@ha/api/v2/types/Kyc';
import { theme, Tooltip } from '@hbf/dsl/legacy';
import {
  CheckCircle1Alternate,
  RoadSignWarningAlternate,
  TimeClockCircle1Alternate,
} from '@hbf/icons/streamline-bold';

import { useIntl } from 'ha/i18n';
import { getIsIdentityVerified } from 'ha/modules/AuthLogic/selectors';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';

const useStyles = makeStyles()({
  tooltip: {
    marginBlock: theme.spacing(0.75),
  },
  payoutStateVerified: {
    color: `${theme.palette.secondary.main} !important`,
  },
  payoutStateNotVerified: {
    color: `${theme.palette.negative.main} !important`,
  },
  payoutStateExpiring: {
    color: `${theme.palette.warning.main} !important`,
  },
  payoutStatePending: {
    color: `${theme.palette.warning.main} !important`,
  },
});

const useIdentityVerification = () => {
  const { enableIdentityVerification } = useFeatureFlags();
  const isIdentityVerified = useSelector(getIsIdentityVerified);

  return {
    isIdentityFFEnabled: isEnabled(enableIdentityVerification),
    isIdentityVerified,
  };
};

// TODO: refactor
// eslint-disable-next-line react/function-component-definition
function KycStatusTooltip({
  kycStatus,
  children,
}: {
  kycStatus: KycStates;
  children: TooltipProps['children'];
}) {
  const { classes } = useStyles();
  const { isIdentityFFEnabled, isIdentityVerified } = useIdentityVerification();

  const { T } = useIntl();

  const title = React.useMemo(() => {
    const titles: Partial<Record<KycStates, string>> = {
      [KycStates.VERIFIED]: T('Verified'),
      [KycStates.PENDING]: T('Pending'),
      [KycStates.EXPIRING]: T('Expiring'),
    };

    if (isIdentityFFEnabled) {
      return isIdentityVerified && kycStatus === KycStates.VERIFIED
        ? T('Verified')
        : T('Not verified');
    }

    return titles[kycStatus] ?? T('Not verified');
  }, [T, kycStatus, isIdentityVerified, isIdentityFFEnabled]);

  return (
    <Tooltip
      title=
      placement="top-start"
      classes={{ tooltip: classes.tooltip }}
    >
      {children}
    </Tooltip>
  );
}

// TODO: refactor
// eslint-disable-next-line react/function-component-definition
export function KycStatusBadge({ kycStatus }: { kycStatus: KycStates }) {
  const { classes } = useStyles();
  const { isIdentityFFEnabled, isIdentityVerified } = useIdentityVerification();

  if (isIdentityFFEnabled) {
    if (kycStatus === KycStates.VERIFIED && isIdentityVerified) {
      return (
        <KycStatusTooltip kycStatus={kycStatus}>
          <CheckCircle1Alternate
            data-test-locator="KycStatusBadge - KycStatusIcon"
            className={classes.payoutStateVerified}
          />
        </KycStatusTooltip>
      );
    }
  } else if (kycStatus === KycStates.VERIFIED) {
    return (
      <KycStatusTooltip kycStatus={kycStatus}>
        <CheckCircle1Alternate
          data-test-locator="KycStatusBadge - KycStatusIcon"
          className={classes.payoutStateVerified}
        />
      </KycStatusTooltip>
    );
  }

  if (kycStatus === KycStates.PENDING) {
    return (
      <KycStatusTooltip kycStatus={kycStatus}>
        <TimeClockCircle1Alternate
          data-test-locator="KycStatusBadge - KycStatusIcon"
          className={classes.payoutStatePending}
        />
      </KycStatusTooltip>
    );
  }

  if (kycStatus === KycStates.EXPIRING) {
    return (
      <KycStatusTooltip kycStatus={kycStatus}>
        <RoadSignWarningAlternate
          data-test-locator="KycStatusBadge - KycStatusIcon"
          className={classes.payoutStateExpiring}
        />
      </KycStatusTooltip>
    );
  }

  return (
    <KycStatusTooltip kycStatus={kycStatus}>
      <RoadSignWarningAlternate
        data-test-locator="KycStatusBadge - KycStatusIcon"
        className={classes.payoutStateNotVerified}
      />
    </KycStatusTooltip>
  );
}
