export const ABOUT = 'About';
export const ACCEPT_PAYMENT_PLAN = 'Accept Payment Plan';
export const ADVERTISER = 'AdvertiserPage';
export const ADVERTISER_SUBSCRIPTION_CATEGORY = 'Advertiser subscription';
export const ALL_ROLES = 'All roles';
export const BILLING = 'Billing';
export const BILLING_SUBSCRIPTION_ACTIVATE = 'Payment form';
export const BILLING_SUBSCRIPTION_LANDING = 'Product selection';
export const BLOG_ADVERTISER = 'Blog advertiser';
export const BLOG_CITY = 'Blog city';
export const BLOG_COUNTRY = 'Blog country';
export const BLOG_TENANT = 'Blog tenant';
export const BOOKING = 'Booking';
export const BOOKING_LINK_PAGE = 'Booking Link';
export const CAREERS = 'Careers';
export const CITY = 'City';
export const COMMISSION_PLAN = 'Commission Plan';
export const CONTACT = 'Contact';
export const CONTACT_ADVERTISER = 'Contact advertiser';
export const COOKIES_POLICY = 'Cookies policy';
export const COOKIES_TABLE = 'Cookies table';
export const COUNTRY = 'Country';
export const COUNTRY_GUIDE = 'CountryGuide';
export const CULTURE = 'Culture';
export const CUSTOMER_SUPPORT = 'Customer Support';
export const DATA_ANALYTICS = 'Data Analytics';
export const EDIT_LISTING = 'Edit listing';
export const EDIT_PROFILE = 'Edit profile';
export const ENGINEERING = 'Engineering';
export const FAQ = 'FAQ';
export const FAVORITE_LISTINGS = 'Favorite listings';
export const FEATURE = 'Feature';
export const FINANCE = 'Finance';
export const GUIDE = 'Guide';
export const HELP_CENTER = 'Help Center';
export const HOME = 'Home';
export const HOW_IT_WORKS = 'How it works next';
export const HOW_WE_HIRE = 'How we hire';
export const INBOX = 'Inbox';
export const INTERNSHIPS = 'Internships';
export const INVOICES = 'Invoices';
export const LEGAL = 'Legal';
export const LIST_YOUR_PLACE = 'List your place';
export const LISTING_CALENDAR = 'Listing calendar';
export const LISTING_CREATION_FLOW = 'LCF';
export const LISTING_DETAIL_PAGE = 'LDP';
export const LISTING_SUCCESS = 'Listing success';
export const LISTINGS = 'Listings';
export const LISTINGS_SETTINGS = 'Listings settings';
export const LOGIN = 'Login';
export const MARKETING = 'Marketing';
export const NOT_FOUND = 'Not found';
export const OTHER = 'Other';
export const PAY = 'Pay';
export const PAY_CONFIRM = 'PayConfirm';
export const PAY_PAYMENT_REQUESTS = 'External Payment';
export const PAYMENT_AND_LEGAL = 'Payment and legal';
export const PAYMENT_METHODS = 'Payment methods';
export const PAYMENT_PLAN = 'Payment plan';
export const PAYMENT_REQUEST_CREATE_MODAL = 'Payment request create modal';
export const PAYMENT_REQUESTS = 'Payment requests';
export const PAYMENTS = 'Payments';
export const PAYMENTS_PAYMENT_REQUESTS = 'Payments Payment requests';
export const PAYOUT_METHODS = 'Payout methods';
export const PAYOUT_ROUTING = 'Payout routing';
export const PAYOUTS_PAYMENT_REQUESTS = 'Payouts payment requests';
export const PEOPLE_OPS = 'People Ops';
export const PRESS_RELEASES = 'Press releases';
export const PRICING = '����VR��Ƶ';
export const PRIVACY_POLICY = 'Privacy policy';
export const PRODUCT_DESIGN = 'Product Design';
export const QUICK_REPLIES_SETTINGS = 'Quick Replies Settings';
export const INBOX_AUTOMATION_SETTINGS = 'Inbox Automation Settings';
export const RECOVER_ACCOUNT = 'Recover account';
export const REGISTER = 'Register';
export const REGISTER_PARTNER = 'Register Partner';
export const RESERVATIONS = 'Reservations';
export const RESET_PASSWORD = 'Reset password';
export const SAFE_PAYMENT_SERVICE = 'Safe payment service';
export const SALES = 'Sales';
export const SEARCH = 'Search';
export const SEARCH_ALERT_LISTINGS = 'Search alert listings';
export const SITEMAP = 'Sitemap';
export const SOMETHING_WENT_WRONG = 'Error';
export const SUBSCRIPTIONS = 'Subscriptions';
export const SUPPLY_LANDING = 'Supply landing';
export const SURVEY_REVIEW = 'Survey';
export const TALK = 'TALK';
export const TENANT_REVIEW_DASHBOARD = 'Tenants review dashboard';
export const TENANT_REVIEW_OVERVIEW = 'Tenants review overview';
export const TENANT_REVIEW_POLICY = 'Review Policy';
export const TENANT_SUBSCRIPTION_CATEGORY = 'Tenant subscription';
export const TERMS = 'Terms';
export const TRANSACTION_HISTORY = 'Transaction history';
export const TRANSACTIONS = 'Transactions';
export const TRUSTPILOT_FEEDBACK_REVIEW = 'TrustPilot feedback review';
export const UNDER_REVIEW = 'Under review';
export const UNIVERSITIES = 'Universities';
export const UNIVERSITY = 'UniLanding';
export const UNIVERSITY_SIGN_UP = 'UniSignup';
export const UNSUPPORTED_COUNTRIES = 'Unsupported Countries';
export const USER = 'UserPage';
export const USER_DASHBOARD = 'User dashboard';
export const VERIFICATION = 'Verification';
export const VERIFY_IDENTITY = 'Verify Identity';
export const VERIFY_PHONE_NUMBER = 'Verify phone number';
