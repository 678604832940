import React, { PropsWithChildren } from 'react';

import { track } from '@hbf/analytics';

import { usePage } from 'ha/modules/Page/contexts/PageContext';

type ContexValues = { [K: string]: any };

export const TrackContext = React.createContext<ContexValues>({});

export const TrackProvider: React.FC<ContexValues> = ({
  children,
  ...props
}) => {
  // merge in parent props if they exist to allow nesting Contexts
  const parentProps = React.useContext(TrackContext);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <TrackContext.Provider value={{ ...parentProps, ...props }}>
      {children}
    </TrackContext.Provider>
  );
};

type Props = {
  name: string;
  getDynamicData?: () => object;
  [K: string]: any;
};

const SmartTrackInternal: React.FC<PropsWithChildren<Props>> = React.forwardRef(
  (
    { children, name, getDynamicData, ...dynamicProps },
    parentRef: React.ForwardedRef<unknown>,
  ) => {
    const contextValues = React.useContext(TrackContext);
    const { name: pageName } = usePage();
    const ref = React.useRef<HTMLElement[]>(
      new Array(React.Children.count(children)),
    );

    const trackEvent = React.useCallback(() => {
      const dynamicData = getDynamicData?.();
      const eventName = name;

      const eventAttributes = {
        page: pageName,
        ...contextValues,
        ...dynamicProps,
        ...dynamicData,
      };

      track(eventName, eventAttributes);
    }, [contextValues, dynamicProps, getDynamicData, name, pageName]);

    React.useEffect(() => {
      if (ref.current.length > 0) {
        const childElements = ref.current;

        childElements.forEach(child => {
          const element = child;
          element?.addEventListener?.('click', trackEvent);
        });

        return () => {
          childElements.forEach(child => {
            const element = child;
            element?.removeEventListener?.('click', trackEvent);
          });
        };
      }
      return undefined;
    }, [trackEvent]);

    const childrenWithref = React.Children.map(children, (child, index) => {
      if (React.isValidElement<React.RefAttributes<HTMLElement>>(child)) {
        const childWithref = React.cloneElement(child, {
          ref: (el: HTMLElement) => {
            ref.current[index] = el;

            if (parentRef) {
              if (typeof parentRef === 'function') parentRef(el);
              else {
                const mutableParentRef =
                  parentRef as React.MutableRefObject<HTMLElement | null>;

                mutableParentRef.current = el;
              }
            }

            const { ref: originalChildRef } = child as
              | React.FunctionComponentElement<React.FunctionComponent>
              | React.ComponentElement<unknown, React.Component>;

            if (originalChildRef) {
              if (typeof originalChildRef === 'function') {
                originalChildRef(el as any);
              } else {
                const mutableParentRef =
                  originalChildRef as React.MutableRefObject<HTMLElement | null>;

                mutableParentRef.current = el;
              }
            }
          },
        });

        return childWithref;
      }

      return null;
    });

    // TODO: remove once upgraded to typescript 5.1
    // https://github.com/DefinitelyTyped/DefinitelyTyped/pull/65135
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <React.Fragment>{childrenWithref}</React.Fragment>;
  },
);

const SmartTrack = React.memo(SmartTrackInternal);
export { SmartTrack, SmartTrack as Track };
