import regularWoff from 'ha/fonts/source-sans-pro-v19-latin_cyrillic-regular.woff';
import regularWoff2 from 'ha/fonts/source-sans-pro-v19-latin_cyrillic-regular.woff2';
import boldWoff from 'ha/fonts/source-sans-pro-v19-latin_cyrillic-600.woff';
import boldWoff2 from 'ha/fonts/source-sans-pro-v19-latin_cyrillic-600.woff2';
import italicWoff from 'ha/fonts/source-sans-pro-v19-latin_cyrillic-italic.woff';
import italicWoff2 from 'ha/fonts/source-sans-pro-v19-latin_cyrillic-italic.woff2';
import italicBoldWoff from 'ha/fonts/source-sans-pro-v19-latin_cyrillic-600italic.woff';
import italicBoldWoff2 from 'ha/fonts/source-sans-pro-v19-latin_cyrillic-600italic.woff2';

import { FontInfo } from 'ha/components/LoadFonts';

const sourceSansPro: FontInfo = {
  fontName: 'Source Sans Pro',
  fontFamily: `'Source Sans Pro', 'Helvetica', 'Arial', 'sans-serif'`,
  preloadWoff2Files: [regularWoff2, boldWoff2],
  stage1Files: [
    {
      src: `local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url('/dist/${regularWoff2}') format('woff2'), url('/dist/${regularWoff}') format('woff')`,
      options: {
        weight: '400',
        style: 'normal',
        display: 'swap',
      },
    },
    {
      src: `local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url('/dist/${boldWoff2}') format('woff2'), url('/dist/${boldWoff}') format('woff')`,
      options: {
        weight: '600',
        style: 'normal',
        display: 'swap',
      },
    },
  ],
  stage2Files: [
    {
      src: `local('Source Sans Pro Italic'), local('SourceSansPro-Italic'), url('/dist/${italicWoff2}') format('woff2'), url('/dist/${italicWoff}') format('woff')`,
      options: { style: 'italic' },
    },
    {
      src: `local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'), url('/dist/${italicBoldWoff2}') format('woff2'), url('/dist/${italicBoldWoff}') format('woff')`,
      options: { style: 'italic', weight: '600' },
    },
  ],
};

export const defaultFonts = [sourceSansPro];
